<template>
  <div class="loginBox flex_c_c">
    <div v-if="!token">
      <div class="logionCard">
        <img class="logo" src="../../../../assets/img/loginLogo.png">
        <img class="loginName" src="../../../../assets/img/loginName.png">

        <div class="flex_b_c">
          <div class="inputLabel">用 户 名</div>
          <div class="flex_l_c item">
            <el-input class="loginIpt" v-model="inputForm.mobilePhone" placeholder="请输入用户名"
                      size="mini"></el-input>
          </div>
        </div>
        <div class="flex_b_c maTops">
          <div class="inputLabel">密 码</div>
          <div class="flex_l_c item">
            <el-input class="loginIpt" v-model="inputForm.password" placeholder="请输入密码" size="mini"
                      :type="passwordShow ? 'password' : 'text'"
                      auto-complete="new-password" @keyup.enter.native="login()">
              <template slot="suffix">
                <div v-show="passwordShow" @click="passwordShow = !passwordShow" class="iconfont icon-eye-close passwordShowIcon"></div>
                <div v-show="!passwordShow" @click="passwordShow = !passwordShow" class="iconfont icon-browse passwordShowIcon"></div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="remmberBotton">
          <el-checkbox class="rembenr" v-model="rememberPwd">记住密码</el-checkbox>
        </div>
        <div class="promptTop">
          <div class="text_right prompt" v-show="numberFailures">
            {{ `第${numberFailures}次登录错误。连续5次登录错误，系统将锁定账号1小时，1小时后才能再次登录。` }}
          </div>
          <div class="flex_b_c">
            <div style="width: 7rem;"></div>
            <div class="loginBtn pointer" @click="login">登录</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>获取用户权限中，请稍后~</div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      token: this.QueryString('token') || '',
      id: this.QueryString('id') || '',
      inputForm: {
        mobilePhone: '',
        password: '',
        type: 1,
        xposition: 1
      },
      rememberPwd: false,
      rules: {
        mobilePhone: [
          {required: true, message: '帐号不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
      },
      numberFailures: 0,
      passwordShow: true,
    }
  },
  mounted() {
    if (this.token) {
      sessionStorage.setItem('token', this.token)
      document.cookie = `satoken=${this.token}`
      this.getUserInfo();
    }
    this.getCookie();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$axios(`${this.api.auth.GetUserById}/${this.id}`, {}, 'get').then(data => {
        if (data.status && data.data) {
          this.$store.commit('user/updateUser', data.data)
          this.getMenus();
        }
      })
    },
    // 获取用户权限
    getMenus() {
      this.$axios(this.api.auth.getMenus, {}, 'get').then(data => {
        console.log(data);
        if (data.status) {
          sessionStorage.setItem('allMenuList', JSON.stringify(data.data.menuList || []))
          sessionStorage.setItem('permissions', JSON.stringify(data.data.permissions || []))
          this.$router.push({name: 'menuHome'})
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 登录
    login() {
      if (this.inputForm.mobilePhone == '' || this.inputForm.password == '') {
        this.$message.error('请输入账号或密码');
        return false
      }
      if (this.rememberPwd) {
        this.setCookie(this.inputForm.mobilePhone, this.inputForm.password, 7);
      } else {
        //清空Cookie
        this.clearCookie();
      }
      this.$axios(this.api.auth.login, this.inputForm, 'post').then(data => {
        if (data.status) {
          sessionStorage.setItem('token', data.data.token)
          document.cookie = `satoken=${data.data.token}`
          this.$store.commit('user/updateUser', data.data)
          this.socketApi.initWebSocket()
          this.getMenus();
        } else {
          this.numberFailures = data.data + 1
          this.$message({
            message: data.msg,
          });
        }
      })
    },
    //储存cookie
    setCookie(name, pwd, exdays) {
      var exdate = new Date();
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      // Encrypt，加密账号密码
      var cipherName = CryptoJS.AES.encrypt(name + "", "secretkey123").toString();
      var cipherPwd = CryptoJS.AES.encrypt(pwd + "", "secretkey123").toString();
      document.cookie = "xxUserName=" + cipherName + ";path=/;expires=" + exdate.toGMTString()
      document.cookie = "xxUserPwd=" + cipherPwd + ";path=/;expires=" + exdate.toGMTString()
    },
    //获取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ');
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=');
          if (arr2[0] == 'xxUserName') {
            var bytes = CryptoJS.AES.decrypt(arr2[1], "secretkey123");
            this.inputForm.mobilePhone = bytes.toString(CryptoJS.enc.Utf8);
          } else if (arr2[0] == 'xxUserPwd') {
            var bytes = CryptoJS.AES.decrypt(arr2[1], "secretkey123");
            this.inputForm.password = bytes.toString(CryptoJS.enc.Utf8);
            if (arr2[1] != "") {
              this.rememberPwd = true;
            }
          }

        }
      }
    },
    //删除cookie
    clearCookie: function () {
      this.setCookie("", "", -1);
    },
  }
}
</script>
<style scoped lang="scss">
.loginBox {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: url('../../../../assets/img/logonBack.png') no-repeat;
  background-size: 100% 100%;

  .logionCard {
    position: absolute;
    right: 7rem;
    top: 50%;
    transform: translateY(-30%);
    width: 46.38rem;
    padding: 7.19rem 5.63rem 2.94rem;
    background: linear-gradient(180deg, #FCCDCD, #FFFFFF);
    border-radius: 1.25rem;

    .logo {
      //top: -167px;
      top: -13.5rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 15.25rem;
    }

    .loginName {
      position: absolute;
      top: -1.46rem;
      left: 50%;
      transform: translateX(-50%);
      width: 31.31rem;
    }

    .maTops {
      margin-top: 2.56rem;
    }

    .inputLabel {
      width: 6rem;
      margin-right: 1rem;
      font-weight: 400;
      font-size: 1.42rem;
      color: #333333;
      text-align: right;
    }

    .item {
      flex: 1;
      height: 3.63rem;
      background: #FFFFFF;
      border-radius: 0.31rem;

      .loginIpt {
        height: 100%;

        &::v-deep .el-input__inner {
          padding: 0 20px;
          height: 100% !important;
          border-color: #EEAFAF;
          background: #FFFFFF;
        }

        &::v-deep .el-input__suffix{
          display: flex;
          align-items: center;
        }
      }
    }

    .remmberBotton {
      margin-top: 1rem;
      margin-left: 7rem;

      .rembenr {
        &::v-deep .el-checkbox__input {
          .el-checkbox__inner {
            border-color: #AB3F3F;
            width: 15px;
            height: 15px;

            &::after {
              top: 1.5px;
              left: 5px;
            }
          }

          .el-checkbox__label {
            padding-left: 8px;
            color: #5A5657;
          }
        }

        /* 设置选中后的文字颜色 */
        &::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #AB3F3F;
        }

        /* 设置选中后对勾框的边框和背景颜色 */
        &::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          border-color: #AB3F3F;
          background-color: #AB3F3F;
        }
      }
    }

    .loginBtn {
      width: 100%;
      height: 3.63rem;
      flex: 1;
      line-height: 3.63rem;
      text-align: center;
      background: #AB3F3F;
      border-radius: 0.31rem;
      font-weight: 400;
      font-size: 1.5rem;
      color: #FFFFFF;
    }

    .promptTop {
      margin-top: 4.31rem;

      .prompt {
        margin-bottom: .5rem;
        margin-right: -2.5rem;
        color: red;
        font-size: 0.9rem;
      }
    }
  }
}
</style>

